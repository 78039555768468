<template>
    <b-navbar class="simple-navbar-main"
        :mobile-burger="false"
        :shadow="true"
        :fixed-top="true">
        <template #brand>
            <b-navbar-item>
                <span class="is-hidden-mobile">
                    <smile-logo
                        :viewBox="desktopViewBox"
                        :height="desktopHeight"
                    />
                </span>
                <span class="is-hidden-tablet">
                    <smile-logo
                        :viewBox="mobileViewBox"
                        :height="mobileHeight"
                    />
                </span>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import Vue from 'vue';
import SmileLogo from './smile-navbar-logo.svg';

export default Vue.extend({
  name: 'SimpleNavbar',
  components: {
    SmileLogo,
  },
  data() {
    return {
      desktopViewBox: '0 0 73 55',
      desktopHeight: '55',
      mobileViewBox: '0 0 73 25',
      mobileHeight: '25',
    };
  },
});
</script>

<style lang="scss" scoped>
.condensed {
  /deep/ #smile-logo-dc {
    display: none;
  }
}
</style>
