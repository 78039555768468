






















import Vue from 'vue';
import SimpleNavbar from '../simple-navbar/simple-navbar.vue';
import GetSightCallCard from '../get-sightcall-card/get-sightcall-card.vue';
import AddToCalendarCard from '../add-to-calendar-card/add-to-calendar-card.vue';

export default Vue.extend({
  name: 'AppointmentInformation',
  components: {
    GetSightCallCard,
    AddToCalendarCard,
    SimpleNavbar,
  },
  props: {
  },
  data() {
    return {
      mainNavbarItems: [],
    };
  },
  methods: {
  },
  computed: {
    appointmentDate(): Date | null {
      return this.$route.query.appointmentDate
        ? new Date(this.$route.query.appointmentDate.toString()) : null;
    },
  },
});
