import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ScheduleCall from '../components/schedule-call/schedule-call.vue';
import AppointmentInformation from '../components/appointment-information/appointment-information.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'ScheduleCall',
    component: ScheduleCall,
    meta: { requiresAuth: true },
  },
  {
    path: '/appointment',
    name: 'AppointmentInformation',
    component: AppointmentInformation,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
