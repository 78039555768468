const setAppointmentCandidates = (state: any, payload: any) => {
  state.appointmentCandidates = payload;
};

const toggleAppointmentCandidatesLoadingStatus = (state: any) => {
  state.loadingAppointmentCandidates = !state.loadingAppointmentCandidates;
};

const toggleAppointmentNotAvailableMessage = (state: any) => {
  state.selectedTimeIsNotAvailable = !state.selectedTimeIsNotAvailable;
};

export default {
  toggleAppointmentCandidatesLoadingStatus,
  setAppointmentCandidates,
  toggleAppointmentNotAvailableMessage,
};
