


















import Vue, { PropType } from 'vue';
import {
  AsYouType,
  parsePhoneNumber,
  CountryCode,
} from 'libphonenumber-js';
import { SmileInput, SmileField } from 'sdc-component-library';

export default Vue.extend({
  name: 'PhoneNumberInput',
  components: {
    SmileInput,
    SmileField,
  },
  model: {
    prop: 'phoneNumber',
    event: 'input',
  },
  props: {
    country: {
      type: String as PropType<CountryCode>,
      default: 'US' as CountryCode,
    },
    placeholder: {
      type: String,
      default: 'Phone Number',
    },
    label: {
      type: String,
      default: 'Phone Number',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    requiredErrorMessage: {
      type: String,
      default: 'This is a required field.',
    },
    formatErrorMessage: {
      type: String,
      default: 'Please enter a valid phone number.',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayNumber: '',
      errorMessage: '',
      isValid: true,
    };
  },
  computed: {
    formattedNumber: {
      get(): string {
        return this.displayNumber;
      },
      set(v: string) {
        const asYouType = new AsYouType(this.country);
        this.displayNumber = asYouType.input(v);
        if (!this.isValid) {
          this.validatePhoneNumber();
        }
        this.$emit('input', asYouType.getChars());
      },
    },
  },
  methods: {
    validatePhoneNumber() {
      if (this.displayNumber === '') {
        this.errorMessage = this.requiredErrorMessage;
        this.isValid = false;
      } else {
        try {
          const phoneNumber = parsePhoneNumber(this.displayNumber, this.country);
          this.isValid = phoneNumber.isValid();
          this.errorMessage = this.isValid ? '' : this.formatErrorMessage;
        } catch (err) {
          this.errorMessage = this.formatErrorMessage;
          this.isValid = false;
        }
      }
      this.emitPhoneNumberValidity();
    },
    enterKeyPressed() {
      this.$emit('enter');
    },
    emitPhoneNumberValidity() {
      this.$emit('isValid', this.isValid);
    },
  },
  watch: {
    phoneNumber() {
      this.formattedNumber = this.phoneNumber;
    },
  },
  mounted() {
    this.formattedNumber = this.phoneNumber;
  },
});
