import axios from 'axios';
import config from '../../config';
import { SalesforceTimeslot, Booking } from '../models/salesforce-models';

const baseUrl = config.BOOKING_API_URL();

async function GetAppointmentCandidates(startTime: string, endTime: string):
  Promise<Array<SalesforceTimeslot>> {
  const res = await axios.get(
    `${baseUrl}/v1/sightcall/schedules/timeslots`,
    {
      params: {
        StartDate: startTime,
        EndDate: endTime,
      },
    },
  );

  return res.data as Array<SalesforceTimeslot>;
}

async function ScheduleAppointment(booking: Booking) {
  const schedulingResponse = await axios.post(`${baseUrl}/v1/sightcall/schedules`, booking);
  return schedulingResponse;
}

export default {
  GetAppointmentCandidates,
  ScheduleAppointment,
};
