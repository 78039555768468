import BookingServiceApi from '../../utils/booking-service-api';
import {
  SalesforceTimeslot,
  StartEndDate,
  DateKeyValue,
} from '../../models/salesforce-models';

const createDatePickerTime = (time: Date, remainingAppts: number) => {
  const hours = time.getHours();
  const minutes = (time.getMinutes() < 10 ? '0' : '') + time.getMinutes();

  return {
    time: `${hours}:${minutes}`,
    disabled: Boolean(!remainingAppts),
    dateTimeObject: time,
  };
};

const intializeDateKV = (date: Date, time: Date, remainingAppts: number) => {
  const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return {
    Key: date.toDateString(),
    Value: {
      date: {
        date: `${date.getDate()}`,
        day: weekday[date.getDay()],
        disabled: true,
        description: 'Unavailable',
      },
      times: [createDatePickerTime(time, remainingAppts)],
    },
  };
};

const setAvailableDates = (appointmentDates: Array<DateKeyValue>) => {
  appointmentDates.forEach((date) => {
    const appt = date.Value;
    if (appt.times.some((time) => !time.disabled)) {
      appt.date.disabled = false;
      appt.date.description = '';
    }
  });
};

const transformCandidates = (response: Array<SalesforceTimeslot>) => {
  const dateKeyValues = [intializeDateKV(new Date(), new Date(), 0)] as Array<DateKeyValue>;

  response.forEach((responseTime) => {
    const date = new Date(responseTime.StartTime);
    const remainingAppts = responseTime.RemainingAppointments;
    const day = dateKeyValues.find(
      (dkv) => dkv.Key === date.toDateString(),
    );
    if (day === undefined) {
      dateKeyValues.push(intializeDateKV(date, date, remainingAppts));
    } else {
      day.Value.times.push(createDatePickerTime(date, remainingAppts));
    }
  });

  setAvailableDates(dateKeyValues);
  dateKeyValues[0].Value.date.description = 'Today';
  dateKeyValues[1].Value.date.description = 'Tomorrow';

  return dateKeyValues.map((dkv) => dkv.Value);
};

const getAppointmentCandidates = async (startEndDate: StartEndDate) => {
  const salesforceResponse = await
  BookingServiceApi.GetAppointmentCandidates(startEndDate.startDate, startEndDate.endDate);

  const appointmentCandidates = transformCandidates(salesforceResponse);

  return appointmentCandidates;
};

export default getAppointmentCandidates;
