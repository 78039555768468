



















import Vue from 'vue';
import { SmileInput, SmileField } from 'sdc-component-library';

export default Vue.extend({
  name: 'EmailAddressInput',
  components: { SmileInput, SmileField },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    labelForEmail: {
      type: String,
      default: 'Email',
    },
    placeholder: {
      type: String,
      default: 'Email',
    },
    requiredErrorMessage: {
      type: String,
      default: 'This is a required field.',
    },
    formatErrorMessage: {
      type: String,
      default: 'Please enter a valid email address.',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorMessage: '',
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  },
  methods: {
    validateEmailInput(emailValue: string) {
      if (emailValue === '') {
        this.errorMessage = this.requiredErrorMessage;
      } else if (!this.reg.test(emailValue) && emailValue !== '') {
        this.errorMessage = this.formatErrorMessage;
      } else {
        this.errorMessage = '';
      }
      this.$emit('isValid', !this.isInvalidEmail);
    },
    enterKeyPressed() {
      this.$emit('enter');
    },
  },
  computed: {
    isInvalidEmail: {
      get(): boolean { return !!this.errorMessage; },
    },
    model: {
      get(): string { return this.value; },
      set(v: string) {
        if (this.errorMessage) {
          this.validateEmailInput(v);
        }
        this.$emit('input', v);
      },
    },
  },
});
