const config = require('./envConfig.json');

const currentEnvironment = (): string => {
  if (process.env.VUE_APP_ENVIRONMENT) {
    return process.env.VUE_APP_ENVIRONMENT;
  }
  return 'development';
};

const BOOKING_API_URL = (): string => config.env[currentEnvironment()].BOOKING_API;

export default {
  currentEnvironment,
  BOOKING_API_URL,
};
