import 'buefy/dist/buefy.css';
import '@smiledirectclub/bulma-based-components/dist/sdc-base.css';
import 'sdc-component-library/dist/sdc.css';
import Vue from 'vue';
import Buefy from 'buefy';
import VueX from 'vuex';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueX);
Vue.use(Buefy);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
