










































































import Vue from 'vue';
import {
  SmileAppointmentScheduler,
  SmileInput,
  SmileButton,
  SmileField,
  SmileMessage,
  SmileLoading,
} from 'sdc-component-library';
import SimpleNavbar from '../simple-navbar/simple-navbar.vue';
import PhoneNumberInput from '../phone-number-input/phone-number-input.vue';
import EmailAddressInput from '../email-address-input/email-address-input.vue';
import GetSightCallCard from '../get-sightcall-card/get-sightcall-card.vue';
import { StartEndDate } from '../../models/salesforce-models';
import BookingApi from '../../utils/booking-service-api';

export default Vue.extend({
  name: 'BookACall',
  components: {
    PhoneNumberInput,
    EmailAddressInput,
    GetSightCallCard,
    SmileAppointmentScheduler,
    SmileButton,
    SmileInput,
    SmileField,
    SmileMessage,
    SmileLoading,
    SimpleNavbar,
  },
  data() {
    return {
      phoneNumber: '',
      email: '',
      firstName: '',
      lastName: '',
      selectedAppointment: {
        date: 0,
        time: 0,
      },
      selectedAppointmentDateTime: new Date(),
      loadingMessage: 'Loading appointments...',
      appointmentLoadingHtml: '<div class="card p-5">Custom component/content here</div>',
      phoneIsValid: false,
      emailIsValid: false,
      mainNavbarItems: [],
      appointments: [{
        date: {
          date: '',
          day: 'Loading Dates...',
          disabled: false,
          description: '',
        },
        times: [
          {
            disabled: false,
            time: 'Loading Times...',
            dateTimeObject: new Date(),
          },
        ],
      }],
      submitting: false,
    };
  },
  methods: {
    async submitForm(e: Event) {
      e.preventDefault();
      if (this.selectedTimeIsNotAvailable) {
        this.toggleAvailabilityMessage();
      }
      if (this.formIsFilled && this.emailIsValid && this.phoneIsValid) {
        try {
          const startTime = this.initialSelectedTime;
          const booking = {
            PhoneNumber: this.phoneNumber,
            EmailAddress: this.email,
            FirstName: this.firstName,
            LastName: this.lastName,
            StartTime: startTime,
          };
          this.submitting = true;
          await BookingApi.ScheduleAppointment(booking);
          this.$router.push({ name: 'AppointmentInformation', query: { appointmentDate: startTime.toString() } });
        } catch (err) {
          this.submitting = false;
          if (err.response.status === 409) {
            this.toggleAvailabilityMessage();
            await this.getAppointments();
          }
        }
      }
    },
    async getAppointments() {
      this.$store.commit('toggleAppointmentCandidatesLoadingStatus');
      await this.$store.dispatch('fetchAppointmentCandidatesForDateRange', this.startEndDate);
      this.appointments = this.$store.state.appointmentCandidates;
      this.$store.commit('toggleAppointmentCandidatesLoadingStatus');
    },
    setPhoneNumberValidity(value: boolean) {
      this.phoneIsValid = value;
    },
    setEmailValidity(value: boolean) {
      this.emailIsValid = value;
    },
    toggleAvailabilityMessage(): void {
      this.$store.commit('toggleAppointmentNotAvailableMessage');
    },
  },
  async mounted() {
    await this.getAppointments();
  },
  computed: {
    appointmentDate(): Date | null | undefined {
      return new Date();
    },
    isLoadingAppointments(): boolean {
      return (this.$store.state.loadingAppointmentCandidates as boolean);
    },
    formIsFilled(): boolean {
      return !!this.firstName
      && !!this.lastName
      && !!this.email
      && !!this.phoneNumber;
    },
    startTime(): Date {
      const start = new Date();
      return new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate() + 1, 0, 0, 0,
      );
    },
    endTime(): Date {
      return new Date(
        this.startTime.getFullYear(),
        this.startTime.getMonth(),
        this.startTime.getDate() + 14, 0, 0, 0,
      );
    },
    startEndDate(): StartEndDate {
      return {
        startDate: this.startTime.toISOString(),
        endDate: this.endTime.toISOString(),
      };
    },
    initialSelectedTime(): Date {
      return this.appointments[this.selectedAppointment.date]
        .times[this.selectedAppointment.time]
        .dateTimeObject;
    },
    selectedTimeIsNotAvailable(): boolean {
      return this.$store.state.selectedTimeIsNotAvailable;
    },
  },
});
