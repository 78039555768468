


























import Vue from 'vue';
import isMobile from 'ismobilejs';
import IosBadge from './ios-badge.vue';
import AndroidBadge from './android-badge.vue';

export default Vue.extend({
  components: { IosBadge, AndroidBadge },
  computed: {
    isiOS(): boolean {
      return isMobile(navigator).apple.device;
    },
    isAndroid(): boolean {
      return isMobile(navigator).android.device;
    },
    isWeb(): boolean {
      return !this.isiOS && !this.isAndroid;
    },
  },
});
