import getAppointmentCandidates from '../components/schedule-call/get-appointments';
import { StartEndDate } from '../models/salesforce-models';

const fetchAppointmentCandidatesForDateRange = async (
  context: any, startEndDates: StartEndDate,
) => {
  const candidates = await getAppointmentCandidates(startEndDates);
  context.commit('setAppointmentCandidates', candidates);
};

export default {
  fetchAppointmentCandidatesForDateRange,
};
