const description = `SmileCall Checklist

Ready. Set. Smile!

We are looking forward to video chatting with you by using our partner app called SightCall. Below are some tips for you, so when you are ready to start video chatting, we can get straight to business.

First things first!
If you have not downloaded the app, let's go ahead and search for "Visual Support" in the App Store or Google Play Store. Here are the links to download the free app to your phone.

App Store (iPhone Users)- https://apps.apple.com/us/app/visual-support/id935317995
Google Play Store (Android Users)-  https://play.google.com/store/apps/details?id=com.sightcall.sightcall

Grab your Aligners and Smile Stretcher.
Make sure you have your aligners out and ready to use. We might need to snap a few photos of your aligners while on the SmileCall, so your Smile Stretcher will come in handy.

Lights, camera, action!
We do want to make sure that you have good lighting during the SmileCall, usually natural lighting or bathroom lighting is best.

Now that's a good connection.
It's ideal to be connected to Wi-Fi, or to make sure that you have enough service during your SmileCall, so there is no lag or delay.

Battery Life
You will want to make sure that your phone does have a good charge, so we don't lose you in the middle of the call.

Beep beep!
Let's stay away from driving while on video chat. We will not be able to complete the SmileCall while you are driving. If you are in your vehicle, please make sure you are parked.

For any questions or concerns, please contact us at customercare@smiledirectclub.com.`;

export default description;
