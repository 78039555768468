

























import Vue, { PropType } from 'vue';
import { ICalendar } from 'datebook';
import { format } from 'fecha';
import { SmileButton } from 'sdc-component-library';
import { Event } from './types';
import EmailDescription from './email-description';

export default Vue.extend({
  components: { SmileButton },
  props: {
    appointmentDate: {
      type: Date as PropType<Date>,
      required: true,
    },
    eventConfig: {
      type: Object as PropType<Event>,
      default: () => ({
        title: 'SmileCall Appointment',
      }),
    },
  },
  data() {
    return {
      appointmentDuration: 20,
      primaryTextClass: 'has-text-left is-family-primary',
      boldTextClass: 'is-size-5 has-text-weight-bold',
    };
  },
  computed: {
    eventEndDate(): Date {
      return new Date(
        this.appointmentDate.getTime() + this.appointmentDuration * 60000,
      );
    },
    eventStartDate(): Date {
      return this.appointmentDate;
    },
    displayDate(): string {
      return format(this.appointmentDate, 'dddd, MMMM D');
    },
    displayTime(): string {
      return format(this.appointmentDate, 'h:mma');
    },
  },
  methods: {
    addToCalendar() {
      const config = {
        ...this.eventConfig,
        start: this.eventStartDate,
        end: this.eventEndDate,
        description: EmailDescription,
      };
      const calendar = new ICalendar(config);
      calendar.download();
    },
  },
});
